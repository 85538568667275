@import "../../style.scss";

.reactions-share {
  @include themify($themes) {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;

    .reactions {
      position: relative;
    }    
    .share-icons {
      position: relative;
    }

    .closed-reaction-menu {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: 14px;
      
      img {
        width: 22px;
        height: 22px;
        object-fit: cover;
        transition: all 0.4s ease-in-out;
      }

      img:hover {
        transform: scale(1.1);
        transform-origin: center;
      }
    }

    .reaction-container.show {
      opacity: 1;
      position: absolute;
      bottom: 30px;
      background-color: themed("bgSoft");
    }

    .reaction-container {
      opacity: 0;
      display: flex;
      border-radius: 10px;
      align-items: center;
      box-shadow: 0px 0px 12px -10px rgba(0, 0, 0, 0.18);
      border: 0.5px solid lightgray;
      padding: 5px;
      position: relative;
      left: 0px;
      
      .section {
        display: flex;
        flex-direction: row;
        font-size: 12px;
        margin-right: 5px;
        margin-top: 0px;
      }
      .reaction {
        margin: 0px 5px;
        display: flex;
        transition: all 0.27s ease-in;

        img {
          width: 20px;
          height: 20px;
          border-radius: 50%;
          object-fit: scale;
        }
      }
      .reaction:hover {
        transform: scale(1.5);
        transform-origin: center;
        margin-top: -10px; 
      }

      .enlarged {
        transform: scale(1.5);
        transform-origin: center;
        margin-top: -10px; /* Adjust the value according to your preference */
      }
    }

    .reaction-icon {
      position: relative;
    
      .label {
        position: absolute;
        top: -20px; // Adjust the distance from the reaction icon according to your preference
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        padding: 2px 5px;
        border-radius: 3px;
        // border: 0.2px solid lightgray;
        font-size: 9px;
        display: none;
        width: max-content; // Set a width larger than the image width
        white-space: nowrap; 
        text-align: center;
        transition: all 0.3s ease-in-out;
        color: #091ebd;
      }
      
      &:hover .label {
        display: block;
      }
    }
  }
}