@import "../../style.scss";

.notifs {
  @include themify($themes) {
    padding: 20px 70px;
    background-color: themed("bgSoft");
    height: 100%;

    @include mobile {
      padding: 10px;
    }

    @include tablet{
      padding: 20px;
    }

    h4 {
      color: #6D1D1D;
      font-size: medium;
      margin: 10px 0px;
    }

    .section {
      border-bottom: 1px lightgray solid;
      padding: 10px 0px;
    }

    .text {
      @include mobile {
        flex-direction: column;
        display: flex;
        gap: 2px;
      }
      display: flex;
    }

    .reaction {
      width: 20px;
      height: 20px;
      align-self: 'center';
      margin: 0px 8px;
    }

    .notif {
      padding: 10px 5px;
      border-radius: 5px;

      .comment-desc {
        margin-left: 63px;
        color: gray;
        font-size: 0.9em;
      }

      .notifRow {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;

        .pfp {
          display: flex;
          gap: 20px;
          align-items: center;

          .profilePic {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            object-fit: cover;
            border: 1px gray solid;
          }

          .date {
            color: gray;
            margin-left: 10px;

            @include mobile {
              margin-left: 0;
            }
          }
        }

        .post img {
          width: 40px;
          height: 40px;
          object-fit: cover;
          justify-self: flex-end;
          justify-content: flex-end;
          border-radius: 0px;
        }

        button {
          background-color: #6D1D1D;
          border-radius: 10px;
          color: white;
          font-weight: bold;
        }
      }
  }

    .notif:hover {
      box-shadow: 0px 0px 20px -10px rgba(0, 0, 0, 0.18);
    }
  }
}