@import "../../style.scss";

.notifs {
  @include themify($themes) {
    padding: 20px 70px;
    background-color: themed("bgSoft");
    height: 100%;
    color: themed("textColor");

    @include mobile {
      padding: 10px;
    }

    @include tablet{
      padding: 20px;
    }

    h4 {
      color: themed("logo");
      font-size: medium;
      margin: 10px 0px;
    }

    .section {
      // border-bottom: 1px lightgray solid;
      padding: 10px 0px;
    }

    .notif:hover {
      box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    }
  }
}