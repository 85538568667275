@import "../../style.scss";

.update {
  @include themify($themes) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .continue {
      border: none;
      padding: 10px;
      border-radius: 5px;
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      cursor: pointer;
      color: white;
      background-color: #6D1D1D;
      width: 20vh;
      align-self: center;
    }

    .close {
      position: absolute;
      top: 10px;
      right: 20px;
      border: none;
      border-radius: 50px;
      background-color: #f0544f;
      padding: 5px;
      cursor: pointer;
      color: white;
    }

    .welcome-msg {
      text-align: center;

      p {
        color: gray;
        margin-top: 5px;
      }
    }

    .wrapper {
      overflow: scroll;
      margin: auto;
      width: 40%;
      height: 70%;
      background-color: themed("bg");
      padding: 50px;
      z-index: 999;
      display: flex;
      flex-direction: column;
      gap: 20px;
      -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      position: relative;
      
      @include mobile {
        width: 90%;
        height: 90%;
        padding: 10px;
      }

      @include tablet {
        width: 80%;
        height: 80%;
        padding: 10px;
      }

      h1 {
        color: themed("logo");
        text-align: center;
      
        @include mobile {
         font-size: 20px;
        }
      
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .files {
          display: flex;
          flex-wrap: wrap;
          gap: 50px;
          justify-content: center;
          align-items: center;
          margin-bottom: 10px;
        }

        .row {
          display: flex;
          align-items: center;
          gap: 25px;

          
        }

        .description {
          font-size: 13px;
          color: themed("textColor");
          margin-top: 5px;
          margin-bottom: 10px;
        }

        label {
          display: flex;
          flex-direction: column;
          gap: 5px;
          color: themed("textColorSoft");
          font-size: 14px;
          font-weight: bold;
          flex-basis: 20%;

          .imgContainer {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 100px;
              height: 100px;
              object-fit: cover;
            }

            .icon {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              font-size: 30px;
              color: lightgray;
              cursor: pointer;
            }
          }
        }

        input {
          padding: 5px 0px;
          border: none;
          border-bottom: 1px solid themed("border");
          color: gray;
          background-color: transparent;
          flex-basis: 60%;
        }

        button {
          border: none;
          padding: 10px;
          margin-top: 10px;
          border-radius: 5px;
          box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
          cursor: pointer;
          color: white;
          background-color: #6D1D1D;
        }
      }
    }
  }
}