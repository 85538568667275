@import "../../style.scss";

.messages {
  @include themify($themes) {
    background-color: themed("bgSoft");
    .thread {
      max-height: 80%;
      background-color: themed("bg");
      -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      overflow-y: scroll;
      padding: 15px;
      display: flex;
      flex-direction: column;
        .convo {
          display: flex;
          flex-direction: column;
        }
        .date {
          align-self: center;
          justify-self: center;
          font-size: 14px;
          color: gray;
        }
        .small {
          font-size: 12px;
          align-self: flex-end;
        }
        .row {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
        }
        .username {
          text-align: center;
          margin-bottom: 10px;
          font-weight: bold;
          font-size: 3vh;
        }
        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          aspect-ratio: 1.0;
          object-fit: cover;
          border: 1px #6D1D1D solid;
          box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        }

        .msg-to {
          /* Basic styling for the container */
            position: relative;
            margin: 15px 10px;
            -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
            -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
            background-color: #6D1D1D;
            padding: 8px 10px;
            border-radius: 10px;
            color: #ffffff;
            width: 200px; /* Set the width as per your requirement */
            margin-left: auto; /* Align to the right side */
            white-space: normal;  /* Allows the text to wrap */
            word-wrap: break-word; /* Ensures long words do not overflow the container */
            overflow-wrap: break-word;
          }

        /* Creating the triangle shape */
        .msg-to::before {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: 40%; /* Adjust this value to move the triangle horizontally */
          border-width: 5px;
          border-style: solid;
          border-color: #6D1D1D transparent transparent transparent;
        }

        .msg-from {
          /* Basic styling for the container */
            justify-self: flex-end;
            position: relative;
            margin-right: auto;
            -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
            -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
            box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
            margin: 15px 10px;
            background-color: slategray;
            padding: 8px 10px;
            border-radius: 10px;
            color: #ffffff;
            width: 200px; /* Set the width as per your requirement */
            white-space: normal;  /* Allows the text to wrap */
            word-wrap: break-word; /* Ensures long words do not overflow the container */
            overflow-wrap: break-word;
        }

        /* Creating the triangle shape */
        .msg-from::before {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -43%; /* Adjust this value to move the triangle horizontally */
          border-width: 5px;
          border-style: solid;
          border-color: slategray transparent transparent transparent;
        }
    }
    
    .writePC{
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 0px 10px;
      background-color: themed("bg");
      justify-self: flex-end;
      height: 18%;

      @include mobile {
        display: none;
      }
      @include tablet {
        display: none;
      }

      input{
        flex:4;
        padding: 10px;
        border: 1px solid themed("border");
        background-color: transparent;
        color: themed("textColor");
      }

      button{
        border: none;
        background-color: transparent;
        padding: 10px;
        cursor: pointer;
        border-radius: 3px;
      }
      img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          border: 1px #6D1D1D solid;
          box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
          aspect-ratio: 1.0;
      }
    }

    .writeMobile{
      @include pc {
        display: none;
      }

      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      padding: 0px 5px;
      background-color: themed("bg");
      justify-self: flex-end;
      height: 18%;
      flex-wrap: wrap;

      input{
        flex: 4;
        padding: 10px;
        border: 1px solid themed("border");
        background-color: transparent;
        color: themed("textColor");
        border-radius: 10px;
      }

      button{
        flex: 0.25;
        border: none;
        background-color: transparent;
        padding: 0px;
        cursor: pointer;
        border-radius: 3px;
        margin: 0px;
      }
      img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
          border: 1px #6D1D1D solid;
          box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
          aspect-ratio: 1.0;
      }
    }
    .section {
      // border-bottom: 1px lightgray solid;
      padding: 10px 0px;
    }

      .post img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        justify-self: flex-end;
        justify-content: flex-end;
        border-radius: 0px;
      }

      button {
        background-color: #6D1D1D;
        border-radius: 10px;
        color: white;
        font-weight: bold;
      }
    }

    .notif:hover {
      box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    }
}