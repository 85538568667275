@import "../../style.scss";

.tamu{
  @include themify($themes) {
    background-color: themed("bgSoft");
    display: flex;
    flex-direction: column;
    .categories {
      margin: 20px 0px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .section {
      margin-top: 30px;
    }

    .background {
      background-color: #6D1D1D;
      padding: 50px 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        padding: 10px 5px;
      }
      @include tablet {
        padding: 20px;
      }

      span {
        color: white;
        text-align: center;
        font-style: italic;

        @include mobile {
          font-size: 0.8em;
        }
      }
    }
    .news-container {
      padding: 30px 50px;
      @include mobile {
        padding: 10px;
      }
      @include tablet {
        padding: 20px;
      }
    }
    .title {
      font-weight: bold;
      color: white;
      font-size: 60px;
      text-align: center;

      @include mobile {
        font-size: 20px;
      }
      @include tablet {
        font-size: 30px;
      }
    }
    .subtitle {
      font-weight: bold;
      font-size: 25px;
      color: themed("textColor");
    }
    .widget {
      border-radius: 10px;
      padding: 5px 10px;
      background-color: #6D1D1D;
      color: white;
      border: 1px lightgray solid;
      align-items: center;
      display: flex;
      gap: 10px;
    }

    .inactive {
      background-color: themed("bg");
      color: themed("textColor");
    }
  }
 }