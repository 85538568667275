@import "../../style.scss";

.category{
  @include themify($themes) {
    background-color: themed("bgSoft");
    padding: 0px;
    margin: 0px;

    .great-container {
      padding: 10px 50px;
      @include mobile {
        padding: 10px;
      }
      @include tablet {
        padding: 20px;
      }
    }

    .categories {
      padding-bottom: 20px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }
    .title {
      font-weight: bold;
      color: white;
      font-size: 60px;
      text-align: center;

      @include mobile {
        font-size: 20px;
      }
      @include tablet {
        font-size: 30px;
      }
    }
    .background {
      background-color: #6D1D1D;
      padding: 50px 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include mobile {
        padding: 10px 5px;
      }
      @include tablet {
        padding: 20px;
      }

      .text-container {
        overflow: hidden;
        white-space: wrap;
        text-align: center;

        .text-content {
            display: inline-block;
        }
      }

      span {
        color: white;
        text-align: center;
        font-style: italic;

        @include mobile {
          font-size: 0.8em;
        }
      }
    }

    .widget {
      border-radius: 10px;
      padding: 5px 10px;
      background-color: #6D1D1D;
      color: white;
      border: 1px lightgray solid;
      align-items: center;
      display: flex;
      gap: 10px;
    }

    .inactive {
      background-color: themed("bg");
      color: themed("textColor");
    }
  }
 }