@import "../../style.scss";

.rightBar {
  @include themify($themes) {
    //position: sticky;
    // top: 70px;
    // height: calc(100vh - 70px);
    // overflow: scroll;
    background-color: themed("bgSoft");
    padding-right: 20px;
    

    @include mobile{
      display: none;
    }
    @include tablet{
      display: none;
    }

    .circle-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;
      text-decoration: none;
      color: inherit;
      margin: 30px 0px;

      img {
        width: 130px;
        height: 130px;
        border: 1px themed("border") solid;
        border-radius: 100%;
        object-fit: contain;
      }

      p {
        color: themed("textColor");
        font-weight: light;
        font-size: large;
      }
      
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .container {
      padding-top: 0px;
      .item {
        //-webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        //-moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        //box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        padding: 20px;
        margin-bottom: 20px;
        background-color: themed("bgSoft");

        span {
          color: gray;
        }

        .user {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 20px 0px;

          .userInfo {
            display: flex;
            align-items: center;
            gap: 20px;
            position: relative;

            img {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              object-fit: cover;
            }

            .online {
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: limegreen;
              position: absolute;
              top: 0;
              left: 30px;
            }

            p {
              color: themed("textColorSoft");
            }

            span {
              font-weight: 500;
              color: themed("textColor");
            }
          }

          .buttons {
            display: flex;
            align-items: center;
            gap: 10px;

            button {
              border: none;
              padding: 5px;
              color: white;
              cursor: pointer;

              &:first-child {
                background-color: #6D1D1D;
              }

              &:last-child {
                background-color: #111111;
              }
            }
          }
        }
      }
    }
  }
}
