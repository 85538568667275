@import "../../style.scss";

.update {
  @include themify($themes) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .pc-none {
      @media screen and (max-width: 480px) {
        display: none !important; 
      }
    }

    .img-label {
      text-align: center;
    }

    .wrapper {
      overflow: scroll;
      margin: auto;
      width: 50%;
      height: 70%;
      background-color: themed("bg");
      padding: 50px;
      z-index: 999;
      display: flex;
      flex-direction: column;
      gap: 20px;
      -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      position: relative;
      color: themed("textColor");
      
      @include mobile {
        width: 90%;
        height: 90%;
        padding: 10px;
      }

      @include tablet {
        width: 80%;
        height: 80%;
        padding: 10px;
      }

      h1 {
        color: themed("logo");
        @include mobile {
         font-size: 20px;
        }
      
      }

      .close {
        position: absolute;
        top: 10px;
        right: 20px;
        border: none;
        background-color: transparent;
        padding: 5px;
        cursor: pointer;
        color: white;
      }

      form {
        input {
          @include mobile {
            flex-basis: 100% !important;
            color: themed("textColor");
          }
        }

        .toggle {
          label {
            @include mobile {
              flex-basis: 50% !important;
            }
          }
        }
      }
    }
  }
}