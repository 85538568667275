@import "../../style.scss";

.grid {
  display: grid;

  @include pc {
    grid-template-columns: 1fr 1fr;
  }

  gap: 12px;
}