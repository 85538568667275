.forgotPassword {
    min-height: 100vh;
    background-color: rgba(109, 29, 29, 0.95);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .language-toggle {
        position: absolute;
        right: 20px;
        top: 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 20px;
    }
    .flag {
        width: 35px;
        height: 35px;
    }

    .card {
        display: flex;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
        align-items: flex-start;
        border: 1px lightgray solid;

        @media screen and (max-width: 1200px) {
        width: 100%;
        border-radius: 0px;
        }

        .right {
            flex: 1;
            padding: 50px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;

            h1 {
            color: #555;
            }
            
            .form {
                display: flex;
                flex-direction: column;
                gap: 30px;
                
                input {
                    border: none;
                    border-bottom: 1px solid lightgray;
                    padding: 20px 10px;
                }           
                
                .notice {
                    font-size: 12px;
                }
            }

            button {
                padding: 10px 20px;
                border: 1px lightgray solid;
                background-color: gray;
                color: white;
                font-weight: bold;
                cursor: pointer;
                align-self: flex-end;
                transition: 0.3s ease-in-out;
            }
            button:hover {
                background-color: #4C1313 !important;
                color: white !important;
            }

            .buttons {
                gap: 20px;
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                justify-content: flex-start;

                .primary {
                    background-color: #6d1d1d;
                    align-self: flex-start;
                }

                button {
                    width: 40%;
                }
            }
            .reset-form {
                margin-top: 20px;
                input {
                    border: none;
                    border-bottom: 1px solid lightgray;
                    padding: 10px;
                    width: 90%;
                }    
                .eye {
                    margin-left: -35px; /* Adjust the margin as needed to align the eye icon to the left */
                    margin-top: 5px;
                    position: absolute;
                }
                button {
                    background-color: #6d1d1d;
                }
            }
        }
    }
}