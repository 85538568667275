@import "../../style.scss";

.viral{
  @include themify($themes) {
    background-color: themed("bgSoft");
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    height: 95%;
  }
}