@import "../../style.scss";

.users {
  @include themify($themes) {
    background-color: themed("bgSoft");
    color: themed("textColor");
    min-height: 95vh;

    h1 {
        padding: 30px 30px 0px 30px;

        @include mobile {
          font-size: 25px;
        }
    }
    h3 {
        font-weight: lighter;
        @include mobile {
          font-size: 18px;
        }
    }
    .user-category {
        padding: 15px 30px;
    }
    .user-list {
        display: flex;
        gap: 20px;
        padding: 10px 0px;
        flex-wrap: wrap;
    }
    .row {
      display: flex;
      gap: 10px;
      align-items: center;

      span {
        font-weight: lighter;
      }
    }
    .user {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: themed("bg");
        padding: 15px;
        border-radius: 10px;
        -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.18);
        -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.18);
        box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.18);
        box-sizing: border-box;
        
        .profilePic {
            width: 15vh;
            height: 15vh;
            border-radius: 50%;
            object-fit: cover;
            margin: auto;
            @include mobile {
              width: 12vh;
              height: 12vh;
            }
            @include tablet {
              width: 12vh;
              height: 12vh;
            }
        }

        span {
            font-weight: lighter;
            margin-top: 10px;
        }

        button {
            background-color: #6D1D1D;
            color: white;
            padding: 5px 10px;
            border-radius: 3px;
            font-size: 13px;
            margin: 5px 0px;
            border: none;
        }
    }

    .search {
        margin: 15px 0px;
        background-color: white;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
        width: 75vw;
      }
  
      .search-list {
        .conversation {
          display: flex;
          align-items: center;
          padding: 10px 0px;
          gap: 20px;
  
          .username {
            font-size: 14px;
          }
        }
      }

    
  }
}
