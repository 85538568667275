@import "../../style.scss";

.share {
  @include themify($themes) {
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    background-color: themed("bg");
    color: themed("textColor");
    margin-bottom: 20px;
    overflow: none;
    user-select: none;

    .character-count {
      position: absolute;
      bottom: 10px;
      right: 10px;
    }

    .right-arrow {
      background-color: transparent;
      border: none;
      z-index: 999;

      @include mobile {
        position: absolute;
        right: 30px;
      }
      @include tablet {
        position: absolute;
        right: 40px;
      }
    }

    .left-arrow {
      background-color: transparent;
      border: none;
      z-index: 999;

      @include mobile {
        position: absolute;
        left: 30px;
      } 
      
      @include tablet {
        position: absolute;
        left: 40px;
      }
    }

    .rnd__option-label {
      @include mobile {
        font-size: 0.8em;
      }
    }

    .categories {
      padding: 10px 0px;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    .x {
      padding: 5px;
      border-radius: 100px;
      border: none;
      text-align: center;
      background-color: transparent;
    }

    .x-carousel {
      padding: 5px;
      border-radius: 100px;
      border: none;
      text-align: center;
      background-color: transparent;
      position: relative;
      right: 0px;
    }
    

    .searchbox-wrapper {
      padding: 20px 30px;
      justify-self: center;
    }

    .rnd__root-menu {
      position: absolute;
      top: -100px;
      left: 0px;
      @include mobile {
        left: auto !important;
      }
      margin: 7px 0;
      background-color: themed("bg");
      bottom: auto !important;
    }

    .rnd__option:hover {
      background-color: themed("bgSoft");
    }

    .rnd__submenu {
      background-color: themed("bg");
      right: 0px;
      z-index: 999;
    }

    .error-msg {
      color: red;
      font-size: 12px;
    }

    .title {
      margin: 35px 0px 20px 0px;
      font-weight: lighter;
    }

    .widget {
      border-radius: 10px;
      padding: 5px 10px;
      background-color: gray;
      color: white;
      border: 1px lightgray solid;
      align-items: center;
      display: flex;
      gap: 10px;
    }

    .inactive {
      background-color: themed("bg");
      color: themed("textColor");
    }

    .flag {
      width: 10%;
      max-width: 500px;
      border: 1px gray solid;
    }

    .category-label {
      padding: 5px;
      border-radius: 5px;
      border: 0px;
      font-size: 12px;
      color: gray;
      background-color: transparent;
      display: flex;
      align-items: center;
      @include mobile {
        padding: 0;
        font-size: 0.9em;
      }
    }

    .red {
      color: red;
    }

    @keyframes floating {
      0% {
        transform: translateY(0); /* Start position */
      }
      50% {
        transform: translateY(-5px); /* Move the button 5 pixels up */
      }
      100% {
        transform: translateY(0); /* Back to the start position */
      }
    }

    .confirmationHeading {
      margin: 8px 0px;
    }
    .confirmationText {
      color: themed("textColorSoft");
    }
    .confirmationButton {
      border: none;
      padding: 8px 30px;
      color: white;
      cursor: pointer;
      background: #6D1D1D;
      border-radius: 50px;
      margin: 20px;
    }
    .confirmationButton:hover {
      animation: floating 2s ease-in-out infinite;
    }

    .thanks-container {
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      -webkit-box-shadow: 0px 0px 15px -8px rgba(0, 0, 0, 0.38);
      -moz-box-shadow: 0px 0px 15px -8px rgba(0, 0, 0, 0.38);
      box-shadow: 0px 0px 15px -8px rgba(0, 0, 0, 0.38);

      button {
        border: none;
        padding: 8px 30px;
        color: white;
        cursor: pointer;
        background: linear-gradient(to bottom, #009911, #009400);
        border-radius: 50px;
        margin: 20px;
      }
      button:hover {
        animation: floating 2s ease-in-out infinite;
      }

      h2 {
        margin-bottom: 10px;
        font-weight: 600;
      }
    }

    .guest-button {
      padding: 5px 10px;
      background-color: #6d1d1d;
      color: white;
      border: none;
      cursor: pointer;
      min-width: 75px;
      border-radius: 4px;
    }

    .row {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
      margin: 30px 10px 0px 0px;
    }


    .container {
      padding: 20px;
      .top {
        display: flex;
        align-items: center;
        gap: 20px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          aspect-ratio: 1.0;
          align-self: flex-start;
        }
        input {
          border: none;
          outline: none;
          padding: 20px 10px;
          background-color: transparent;
          width: 60%;
          color: themed("textColor");
          overflow: wrap;
          user-select: none;
        }
        textarea {
          border: none;
          outline: none;
          background-color: transparent;
          width: 100%;
          height: 100%;
          color: themed("textColor");
          overflow: wrap;
          resize: none;

          @include mobile {
            font-size: 1em;
          }
        }

        .textInput {
          color: themed("textColorSoft");
          font-size: 0.9em;
        }

        .right {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          .file {
            width: 300px;
            height: 300px;
            object-fit: contain;
            border-radius: 0px;
          }
        }
      }

      video {
        width: 300px;
        object-fit: contain;
        align-self: center;
      }

      .file {
        width: 300px;
        height: 300px;
        object-fit: contain;
        max-width: 500px;
      }

      .filePreview {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: themed("border");
        padding: 10px;
      }

      .loading-circle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 40px;
        height: 40px;
        border: 4px solid rgba(0, 0, 255, 0.3);
        border-top: 4px solid blue;
        border-radius: 50%;
        animation: spin 1s linear infinite;
      }
      
      @keyframes spin {
        0% { transform: translate(-50%, -50%) rotate(0deg); }
        100% { transform: translate(-50%, -50%) rotate(360deg); }
      }
      
      .loading-text {
        margin-top: 20px;
        font-size: 24px;
        letter-spacing: 4px;
        user-select: none;
      }
      
      .loading-text span {
        display: inline-block;
        animation: float 1.5s ease-in-out infinite;
      }
      
      .loading-text span:nth-child(2) {
        animation-delay: 0.2s;
      }
      
      .loading-text span:nth-child(3) {
        animation-delay: 0.4s;
      }
      
      @keyframes float {
        0%, 100% { transform: translateX(0); }
        50% { transform: translateX(10px); }
      }

      .middle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
      }

      hr {
        margin: 20px 0px;
        border: none;
        height: 0.5px;
        background-color: themed("border");
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          gap: 20px;
          
          @include mobile {
            flex-direction: column;
            gap: 10px;
            align-items: flex-start;
          }

          .item {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            img {
              height: 20px;
            }

            span {
              font-size: 0.9em;
              color: gray;
            }

            input {
              font-size: 0.9em !important;
            }
          }
        }
        .right {
          button {
            border: none;
            padding: 5px 10px;
            color: white;
            cursor: pointer;
            background-color: #6D1D1D;
            border-radius: 3px;

            @include mobile {
              font-size: 1em;
            }
          }
        }
      }
    }
  }
}
