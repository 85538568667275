@import "../../style.scss";

.login {
  min-height: 100vh;
  background-color: #6D1D1D;
  display: flex;
  align-items: center;
  justify-content: center;

  .error {
    color: red;
  }

  button {
    transition: all 0.3s ease-in !important;
  }
  button:hover {
    background-color: #4C1313 !important;
    color: white !important;
  }

  .or {
    font-size: 0.8em;
    color: #555;
    text-align: center;
  }

  .forgot {
    font-size: 0.8em;
    width: 100%;
    flex-wrap: nowrap;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 60%;
  }

  .guest {
    button {
      background-color: white !important;
      color: black !important;
      font-weight: normal !important;
      border: 1px solid lightgray !important;
      width: fit-content;
    }
    button:hover {
      background-color: #4C1313 !important;
      color: white !important;
    }
  }

  .language-toggle {
    position: absolute;
    right: 20px;
    top: 20px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: transparent !important;
  }
  .language-toggle:hover {
    background-color: transparent !important;
  }
  .flag {
    width: 35px;
    height: 35px;
  }

  .card {
    width: 60%;
    display: flex;
    background-color: white;
    border-radius: 10px;
    min-height: 600px;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 1200px) {
      width: 100%;
      border-radius: 0px;
    }

    .left {
      @media screen and (max-width: 1200px) {
        display: none; 
      }
      flex: 1;
      background: linear-gradient(rgba(58, 15, 15, 0.65), rgba(58, 15, 15, 0.65)),
        url("https://images.pexels.com/photos/3228727/pexels-photo-3228727.jpeg?auto=compress&cs=tinysrgb&w=1600")
          center;
      background-size: cover;
      padding: 50px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      color: white;

      h1 {
        font-size: 80px;
        line-height: 80px;
      }

      span {
        font-size: 14px;
      }
      
      button {
        width: 50%;
        padding: 10px;
        border: none;
        background-color: white;
        color: #4C1313;
        font-weight: bold;
        cursor: pointer;
      }
    }

    .right {

      flex: 1;
      padding: 50px;
      display: flex;
      flex-direction: column;
      gap: 50px;
      justify-content: center;

      h1 {
      color: #555;
      }
      
      form {
        display: flex;
        flex-direction: column;
        gap: 30px;
        
        input {
          border: none;
          border-bottom: 1px solid lightgray;
          padding: 20px 10px;
          width: 100%;
        }

        .eye {
          position: absolute;
          left: 90%;
          top: 25%;
          color: #555;
          
        }
        
        
        button {
          padding: 10px;
          border: none;
          background-color: #BB7E7E;
          color: white;
          font-weight: bold;
          cursor: pointer;
          width: 100%;
        }
      }
    }
  }

  .mobile {
    @media screen and (min-width: 1201px) {
      display: none; 
    }
  }
  
  .pc {
    @media screen and (max-width: 1200px) {
      display: none; 
    }
  }
}
