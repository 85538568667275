@import "../../style.scss";

.stories {
  @include themify($themes) {
    display: flex;
    gap: 20px;
    height: fit-content;
    justify-content: flex-start;
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 70vw;

    @include mobile {
      max-width: 100vw;
    }

    .x {
      padding: 5px;
      position: absolute;
      top: 0;
      right: 0;
      color: themed("text");
      background-color: themed("bg");
      border-radius: 100px;
      border: none;
      text-align: center;
      background-color: transparent;
    }
    
    /* WebKit scrollbar styles */
    &::-webkit-scrollbar {
      height: 20px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-button {
      display: block;
      height: 25px;
    }

    &::-webkit-scrollbar-button:start {
      /* Set your left arrow image */
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M20 0H0v20h20zm-7.354 14.166-1.389 1.439-5.737-5.529 5.729-5.657 1.4 1.424-4.267 4.215z"/></svg>');
      background-repeat: no-repeat;
      background-position: center;
      width: 20px;
    }

    &::-webkit-scrollbar-button:end {
      /* Set your right arrow image */
      background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"><path d="M20 0H0v20h20zM7.658 15.707l-1.414-1.414L10.537 10 6.244 5.707l1.414-1.414L13.365 10z"/></svg>');
      background-repeat: no-repeat;
      background-position: center;
      width: 20px;
    }


    .shareStory {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 999;
    
        .wrapper {
          margin: auto;
          width: 50%;
          height: fit-content;
          background-color: themed("bg");
          padding: 50px;
          z-index: 999;
          display: flex;
          flex-direction: column;
          gap: 20px;
          -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
          -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
          box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
          position: relative;
          color: themed("textColor");

          @include mobile {
            width: 80%;
            height: 50%;
            padding: 20px;
          }
          
          .post {
            padding: 10px 15px;
            background-color: #6D1D1D;
            color: white;
            width: fit-content;
            border-radius: 10px;
            font-size: 14px;
            border: none;
            cursor: pointer;
            align-self: flex-end;
            position: absolute;
            bottom: 10px;
            right: 10px;
          }

          .close {
            padding: 4px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: none;
            background-color: transparent;
            color: white;
            position: absolute;
            top: 10px;
            right: 10px;
            width: 20px;
            border-radius: 100px;
          }

          h2 {
            font-weight: bold;
            color: themed("logo");
          }

          .file {
            width: 200px;
            aspect-ratio: 0.85;
            object-fit: cover;
            border-radius: 20px;
          }

          .item {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            img {
              height: 30px;
            }

            span {
              font-size: 14px;
              color: gray;
            }
          }
        }
      }
    }

    .story {
      position: relative;

      img {
        width: 200px;
        height: 250px;
        object-fit: cover;
        border-radius: 10px;
      }
      span {
        position: absolute;
        left: 10px;
        color: black;
        background-color: #f6f3f3;
        border-radius: 5px;
        padding: 1px 5px;
        font-weight: 500;
      }

      button {
        position: absolute;
        right: 10px;
        color: white;
        background-color: #6D1D1D;
        border: none;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        cursor: pointer;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .lower {
        bottom: 45px;
      }
    }
}
