@import "../../style.scss";

.submit-job {
  @include themify($themes) {
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    background-color: themed("bg");
    color: themed("textColor");
    margin-bottom: 20px;
    width: 100%;

    .error-msg {
      color: red;
      font-size: 12px;
    }

    .red {
      color: red;
    }

    label {
      @include mobile {
        flex-basis: 40% !important;
      }
    }
    
    .dropdown {
        background-color: transparent;
        box-shadow: none;
        color: black;
        margin: 0px;
        border: 1px lightgray solid;
        padding: 5px;
        text-align: left;
        border-radius: 5px;
    }

    .container {
      padding: 20px;
      .top {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }
        }

      .middle {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;

        @include mobile {
          input {
            font-size: 1em;
          }
        }
        .x {
          background-color: transparent;
          border: none;
        }
        .file {
          width: 200px;
          height: 200px;
        }
      }

      hr {
        margin: 20px 0px;
        border: none;
        height: 0.5px;
        background-color: themed("border");
      }

      .rnd__option-label {
        @include mobile {
          font-size: 0.8em;
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          gap: 20px;

          .item {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            img {
              height: 20px;
            }

            span {
              font-size: 12px;
              color: gray;

              @include mobile {
                font-size: 0.9em;
              }
            }
          }
        }
        .right {
          button {
            border: none;
            padding: 5px 10px;
            color: white;
            cursor: pointer;
            background-color: #6D1D1D;
            border-radius: 3px;

            font-size: 1em;
          }
        }
      }
    }
  }
}
