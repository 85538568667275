@import "../../style.scss";

.soft {
  @include themify($themes) {
    background-color: transparent;
  }
}

.toggle-closed {
  @include themify($themes) {
    position: fixed;
    z-index: 999;
  }
}

.mobile {
  @include pc {
    display: none !important;
  }
}

.pc {
  @include mobile {
    display: none !important;
  }
}

.leftBar {
  @include themify($themes) {
    position: sticky;
    top: 70px;
    height: calc(100vh - 70px);
    overflow: scroll;
    background-color: themed("bg");
    color: themed("textColor");
    transition: transform 0.5s ease-in-out;
    padding-right: 50px;

    @include mobile {
      padding-right: 20px;
    }

    .soft {
      background-color: themed("bgSoft");
    }

    &.closed {
      transform: translateX(-100%); // Slide out
      position: absolute;
    }

    &.open {
      transform: translateX(0); // Slide in
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .row {
      display: flex;
      align-items: center;
      gap: 10px;
      overflow: wrap;
    }

    .toggle {
      position: absolute;
      right: 10px;
      bottom: 10px; 
      z-index: 999;

      @include mobile {
        top: 10px;
      }
    }

    .logout {
      position: absolute;
      right: 10px;
      bottom: 40px;
      justify-self: flex-end;
      display: flex;
      align-items: center;
      overflow: wrap;
      gap: 10px;

      @include mobile {
        bottom: 10px;
      }

      button {
        padding: 2px 5px;
      }
    }
    

    .container {
      padding: 20px;

      hr {
        margin: 20px 0px;
        border: none;
        height: 0.5px;
        background-color: themed("border");
      }

      .menu {
        display: flex;
        flex-direction: column;
        gap: 20px;

        span {
          font-size: 12px;
        }

        .user {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
          }

          span {
            font-size: 16px;
          }
        }

        .item {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 30px;
          }

          span {
            font-size: 16px;
          }
        }

        .categoryLabel {
          padding-left: 20px;
          font-size: 12px;
          
        }

        .subcategoryLabel {
          padding-left: 40px;
          font-size: 12px;
          font-weight: lighter;
        }
        
      }

      .submenu {
        display: flex;
        flex-direction: column;
        gap: 15px;

        span {
          font-size: 14px;
        }

        .user {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
          }

          span {
            font-size: 14px;
          }
        }

        .item {
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            width: 30px;
          }

          span {
            font-size: 14px;
          }
        }

        .categoryLabel {
          padding-left: 20px;
          font-size: 14px;
        }

        .subcategoryLabel {
          padding-left: 40px;
          font-size: 13px;
          font-weight: lighter;
        }
        
      }
    }
  }
}
