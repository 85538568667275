@import "../../style.scss";

.navbar {
  @include themify($themes) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    height: 50px;
    border-bottom: 1px solid themed("border");
    position: sticky;
    top: 0;
    background-color: themed("bg");
    color: themed("textColor");
    z-index: 999;
    flex-wrap: nowrap;
    width: calc(100% - 40px);

    .mobile {
      @media screen and (min-width: 581px) {
        display: none; 
      }
    }
    
    .pc {
      @media screen and (max-width: 580px) {
        display: none; 
      }
    }

    .guest-button {
      padding: 5px 10px;
      background-color: #6d1d1d;
      color: white;
      border: none;
      cursor: pointer;
      border-radius: 4px;
      font-size: 0.9em;
    }
    .guest-button-second {
      padding: 5px 10px;
      background-color: themed("bg");
      color: themed("textColor");
      border: none;
      cursor: pointer;
      border-radius: 4px;
      font-size: 0.9em;
    }

    .row {
      display: flex;
      gap: 10px;
    }

    .language-toggle {
      background-color: transparent;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0px;
    }

    .flag {
      width: 25px;
      height: 25px;
    }

    .left {
      display: flex;
      align-items: center;
      gap: 30px;

      @include mobile {
        gap: 10px;
      }
      @include tablet {
        gap: 10px;
      }

      span {
        font-weight: bold;
        font-size: 20px;
        color: themed("logo");

        @include mobile {
          font-size: 18px;
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 20px;

      @include mobile {
        gap: 10px;
      }

      .user {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: 500;

        img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          object-fit: cover;
        }

        span {}
      }
    }
  }
}
