@import "../../style.scss";

.share-short {
  @include themify($themes) {
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    background-color: themed("bg");
    color: themed("textColor");
    margin-bottom: 20px;
    width: fit-content;

    @include mobile {
        width: 100%;
    }
    @include tablet {
      min-width: 50%;
    }

    .error-msg {
      color: red;
      font-size: 12px;
    }

    .red {
      color: red;
    }

    label {
      @include mobile {
        flex-basis: 40% !important;
      }
    }

    video {
        height: 60vh;

        @include mobile {
            height: fit-content;
            width: 80vw;
        }
    }

    .preview {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .x {
        background-color: transparent;
        border: none;
        position: relative;
        right: -50%;
        margin-right: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        top: 0;
    }

    .confirmationHeading {
      margin: 8px 0px;
    }
    .confirmationText {
      color: themed("textColorSoft");
    }
    .filePreview {
      width: 150px;
      height: 150px;
      object-fit: contain;
      background-color: themed("border");
      padding: 10px;
    }

    .loading-circle {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;
      border: 4px solid rgba(0, 0, 255, 0.3);
      border-top: 4px solid blue;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }
    
    @keyframes spin {
      0% { transform: translate(-50%, -50%) rotate(0deg); }
      100% { transform: translate(-50%, -50%) rotate(360deg); }
    }
    
    .loading-text {
      margin-top: 20px;
      font-size: 24px;
      letter-spacing: 4px;
      user-select: none;
    }
    
    .loading-text span {
      display: inline-block;
      animation: float 1.5s ease-in-out infinite;
    }
    
    .loading-text span:nth-child(2) {
      animation-delay: 0.2s;
    }
    
    .loading-text span:nth-child(3) {
      animation-delay: 0.4s;
    }
    
    @keyframes float {
      0%, 100% { transform: translateX(0); }
      50% { transform: translateX(10px); }
    }

    .guest-container {
      padding: 20px;

      .top {
        display: flex;
        align-items: center;
        gap: 20px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
          aspect-ratio: 1.0;
        }

        span {
          color: themed("textColorSoft");
        }
      }

      hr {
        margin: 20px 0px;
        border: none;
        height: 0.5px;
        background-color: themed("border");
      }

      .guest-button {
        padding: 5px 10px;
        background-color: #6d1d1d;
        color: white;
        border: none;
        cursor: pointer;
        min-width: 75px;
        border-radius: 4px;
        justify-self: flex-end;
      }
    }
    
    .container {
      padding: 20px;

      .directions {
        font-size: 1em;
        color: gray,
      }

      hr {
        margin: 20px 0px;
        border: none;
        height: 0.5px;
        background-color: themed("border");
      }

      .bottom {

        img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            object-fit: cover;
            aspect-ratio: 1.0 !important;
        }
  
        input {
            border: none;
            outline: none;
            padding: 10px;
            background-color: transparent;
            width: 100%;
            color: themed("textColor");
            overflow: wrap;
            resize: none;

            @include mobile {
              font-size: 1em !important;
            }
        }

        display: flex;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          align-items: center;
          gap: 10px;

          .item {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            img {
              height: 20px;
            }

            span {
              font-size: 12px;
              color: gray;
            }
          }
        }
        .right {
          button {
            border: none;
            padding: 5px 10px;
            color: white;
            cursor: pointer;
            background-color: #6D1D1D;
            border-radius: 3px;

            @include mobile {
              font-size: 1em !important;
            }
          }
        }
      }
    }
  }
}

.short {
  @include themify($themes) {
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    background-color: themed("bg");
    color: themed("textColor");
    margin: 20px 0px;
    width: fit-content;

    p {
      margin-top: 10px;
    }

    .container {
      padding: 20px;
    }
    
    .deleteMenu {
      display: flex;
      justify-content: flex-end;
      gap: 15px;
      cursor: pointer;

      button {
        padding: 0px 5px;
      }
    }

    .userInfo {
      display: flex;
      gap: 15px;
      align-items: center;

      img {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        object-fit: cover;
      }

      .details {
        display: flex;
        flex-direction: column;

        .name {
          font-weight: 500;
          font-size: 0.9em;
        }

        .date {
          font-size: 12px;
          color: gray;
        }
      }
    }

    video {
      height: 75vh;
      max-width: 90vw;
      object-fit: contain;
      align-self: center;
      margin: 10px 0px;

      @include tablet {
        width: 90vw;
        height: fit-content;
        max-height: 80vh;
      }
      @include mobile {
        width: 90vw;
        height: fit-content;
        max-height: 80vh;
      }
    }
  }
}

