@import "../../style.scss";

.event {
  @include themify($themes) {
    -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
    border-radius: 20px;
    background-color: themed("bg");
    color: themed("textColor");
    overflow: hidden;
    .container {
        display: flex;
        flex-direction: column;
        justify-content: center;

      .top {
        background-color: #6D1D1D;
        color: white;
        border-radius: 20px 20px 0px 0px;
        text-align: center;
        padding: 10px 0px;
        h3 {
          font-weight: lighter;
          font-size: 25px;
        }
      }

      .center {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: center;
      }

      .delete {
        padding: 0px 5px;
      }

      .user {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            font-size: 12px;
        }

        .userInfo {
          display: flex;
          gap: 10px;
          align-items: center;
          margin: 10px 0px;

          img {
            width: 25px;
            height: 25px;
            border-radius: 50%;
            object-fit: cover;
          }

          .details {
            display: flex;
            flex-direction: column;

            .name {
              font-weight: 500;
            }

            .date {
              font-size: 12px;
            }
          }
          
        }
      }

      .link {
        color: themed("linkColor");
        text-decoration: underline;
      }

      .row {
        display: flex;
        gap: 10px;
        margin: 5px 0px;
      }

      .content {
        padding: 20px;

        img {
          width: 100%;
          max-height: 500px;
          object-fit: cover;
          margin-top: 20px;
        }

        video {
            width: 100%;
            max-height: 500px;
            object-fit: cover;
            margin-top: 20px;
        }
      }

      .info {
        display: flex;
        align-items: center;
        gap: 20px;
        width: 100%;

        .like-count {
          display: flex;
          justify-content: flex-end;
          align-items: center;
        }

        .item {
          display: flex;
          align-items: center;
          gap: 10px;
          cursor: pointer;
          font-size: 14px;
          
          img {
            width: 22px;
            height: 22px;
            object-fit: cover;
            transition: all 0.4s ease-in-out;
          }

          img:hover {
            transform: scale(1.1);
            transform-origin: center;
          }
        }
      }
    }
  }
}
