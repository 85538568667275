@import "../../style.scss";

.profile {
  @include themify($themes) {
    background-color: themed("bgSoft");

    .images {
      width: 100%;
      height: 300px;
      position: relative;

      .cover {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .row {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: center;
      }

      .profilePic {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        object-fit: cover;
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        top: 200px;
      }
    }
    .profileContainer {
      padding: 20px 70px;

      @include mobile{
        padding: 10px;
      }

      @include tablet{
        padding: 20px;
      }

      .bio {
        text-align: center;
        max-width: 75vh;
      }

      .uInfo {
        -webkit-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
        -moz-box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
        box-shadow: 0px 0px 25px -10px rgba(0, 0, 0, 0.38);
        border-radius: 20px;
        background-color: themed("bg");
        color: themed("textColor");
        padding: 60px 50px 20px 50px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        @include mobile{
          flex-direction: column;
          height: 30vh;
          padding: 20px;
          margin-top: 100px;
        }

        .left {
          flex: 1;
          display: flex;
          gap: 10px;

          @include tablet{
            flex-wrap: wrap;
          }

          a{
            color: themed("textColorSoft");
          }
        }

        .center {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 10px;

          .name {
            margin-top: 20px;
            text-align: center;
          }
          span {
            font-size: 30px;
            font-weight: 500;
          }

          .business-type {
            color: gray;
            font-weight: light;
          }

          .info {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;

            .item {
              display: flex;
              align-items: center;
              gap: 5px;
              color: themed("textColorSoft");
              
              a {
                color: inherit;
                text-decoration: none;
                align-items: center;
                display: flex;
                gap: 5px;

                span {
                  color: themed("linkColor");
                  text-decoration: underline;
                }
              }
              span {
                font-size: 12px;
              }
            }
          }
          button {
            border: none;
            background-color: #6D1D1D;
            color: white;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            margin: 10px 0px;
          }
        }

        .right {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 10px;
        }
      }
    }
  }
}
