@import "../../style.scss";

.guest {
  @include themify($themes) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    .guest-button {
      padding: 10px 15px;
      background-color: #6d1d1d;
      color: white;
      border: none;
      cursor: pointer;
    }

    .row {
      display: flex;
      gap: 20px;
      justify-content: flex-end;
    }

    .wrapper {
      overflow: scroll;
      margin: auto;
      width: 40%;
      min-width: 300px;
      height: 20%;
      background-color: themed("bg");
      padding: 50px;
      z-index: 999;
      display: flex;
      flex-direction: column;
      gap: 20px;
      -webkit-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      -moz-box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.09);
      position: relative;
      color: themed("textColor");

      @include mobile {
        width: 100%;
        height: 100%;
      }

      h1 {
        color: themed("logo");
        @include mobile {
         font-size: 20px;
        }
      }

      .close {
        position: absolute;
        top: 10px;
        right: 20px;
        border: none;
        background-color: transparent;
        padding: 5px;
        cursor: pointer;
        color: white;
      }
      
    }
  }
}